import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const MemberForm = () => {
    const [formData,setFormData] = useState({first_name: "", last_name: "", email: "", phone: "", birthday: "", address: ""})
    const navigate = useNavigate()
    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/email/new-member`,formData)
            console.log(response)
            navigate("/appt")  
        } catch (error) {
            console.log(error)
            window.location.reload()   
        }
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-md-6">
                    <input required type="text" name="fist_name" placeholder="First Name..." onChange={(e) => setFormData({...formData, first_name: e.target.value})} />
                </div>
                <div className="col-md-6">
                    <input required type="text" name="last_name" placeholder="Last Name..." onChange={(e) => setFormData({...formData, last_name: e.target.value})} />
                </div>
                <div className="col-md-6">
                    <input required type="text" name="email" placeholder="Email..." onChange={(e) => setFormData({...formData, email: e.target.value})} />
                </div>
                <div className="col-md-6">
                    <input required type="text" name="phone" placeholder="Phone #..." onChange={(e) => setFormData({...formData, phone: e.target.value})} />
                </div>
                <div className="col-md-6">
                    <input required type="date" name="birthday" placeholder="Birthday..." onChange={(e) => setFormData({...formData, birthday: e.target.value})} />
                </div>
                <div className="col-md-6">
                    <input required type="text" name="address" placeholder="Home Address..." onChange={(e) => setFormData({...formData, address: e.target.value})} />
                </div>
                <div className="col-12">
                    <div className="button--wrap">
                        <button className="eg-btn btn--primary golf-btn mx-auto" type="submit"><span>Join now <i className="bi bi-arrow-right"></i></span></button>
                    </div>
                </div>
            </div>
        </form>
    )
}

export default MemberForm