const Feature = ({props}) => {
    return (
        <div className="col-md-6 col-lg-3">
            <div className="single-feature">
                <div className="icon">
                    <i><img src={props.img} alt="" /></i>
                </div>
                <span className="counter">{props.num}</span> <b>{props.title}</b>
            </div>
        </div>
    )
}

export default Feature