import { clubServices } from "../../lib/tmpData"
import Facility from "./Facility"

const Club = () => {
    return (
        <div className="our-club sec-mar-bottom" style={{marginTop: "2em"}}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6">
                        <div className="title text-center">
                            <span>Our club</span>
                            <h2>Discover our Club Facilities</h2>
                        </div>
                    </div>
                </div>
                <div className="club-facilities">
                    <div className="row g-4">
                        {clubServices.map(service => <Facility props={service} key={service.title} />)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Club