const Events = () => {
    const handleSideNav = () => {
        if (document.querySelector(".main-nav").classList.contains("slidenav")) {
            document.querySelector(".main-nav").classList.remove("slidenav")
        }
    }

    return (
        <div onClick={handleSideNav}>


            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb-inner">
                                    <h1>Events</h1>
                                    <span><a href="/">Home<i className="fas fa-angle-right"></i></a>Events</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field-review sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-xl-6 or2">
                            <div className="review-content">
                                <div className="title">
                                    <span>Riverside Events</span>
                                    <h2>Our banquet facility.</h2>
                                </div>
                                <p>Sed vestibulum ex lectus, ac sollicitudin erat fringilla at. Donec vehicula sollici tudin,oi mi nec posuere scelerisque, sapien massa maximus ipsum,vel arcu. Ut rutrum elit nibh, sit amet aliquam.</p>
                                <ul className="field-points">
                                    <li><i className="far fa-check-circle"></i> Sed vestibulum ex lectus aceni.</li>
                                    <li><i className="far fa-check-circle"></i> Donec Sollicitudin minec posue.</li>
                                    <li><i className="far fa-check-circle"></i> Cras sed erat non enim interd.</li>
                                    <li><i className="far fa-check-circle"></i> Suspendisse rutrum ex aliqueti.</li>
                                    <li><i className="far fa-check-circle"></i> scelerisque sapien massa maxi.</li>
                                    <li><i className="far fa-check-circle"></i> varius vehicula libvel archu ru.</li>
                                    <li><i className="far fa-check-circle"></i> Mauris lobortis mauris sit ame.</li>
                                    <li><i className="far fa-check-circle"></i> Sed vestibulum ex lectus aceni.</li>
                                </ul>
                                <div className="button--wrap">
                                    <a href="/contact" className="eg-btn btn--primary golf-btn mx-auto">Schedule Event <i className="bi bi-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-xl-6 or1">
                            <div className="field-img">
                                <img src="assets/img/field-review.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Events