const ApplicationThanks = () => {

    const handleSideNav = () => {
        if (document.querySelector(".main-nav").classList.contains("slidenav")) {
            document.querySelector(".main-nav").classList.remove("slidenav")
        }
    }

    return (
        <div onClick={handleSideNav}>
            
            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb-inner">
                                    <h1>Membership Application</h1>
                                    <span><a href="/">Home<i className="fas fa-angle-right"></i></a>Membership Application</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{display: "flex", flexDirection: "column", alignItems: "center", minHeight: "50vh", justifyContent: "center"}}>
                <div>
                    <h2>Thank you for applying!</h2>
                </div>
                <div>
                    <p className="text-muted">A member of the Riverside staff will contact you with further details.</p>
                </div>
                <div>
                    <a className="btn btn-primary" style={{borderRadius: "14px"}} href="/">Back to home</a>
                </div>
            </div>
        </div>
    )
}

export default ApplicationThanks