import { tournaments } from "../../lib/tmpData"

const Tournaments = () => {

    const handleSideNav = () => {
        if (document.querySelector(".main-nav").classList.contains("slidenav")) {
            document.querySelector(".main-nav").classList.remove("slidenav")
        }
    }

    return (
        <div onClick={handleSideNav}>

            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb-inner">
                                    <h1>Tournaments</h1>
                                    <span><a href="/">Home<i className="fas fa-angle-right"></i></a>Tournaments</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="title text-center" style={{marginTop: "6em"}}>
                <h2>Riverside's Scheduled Tournaments</h2>
            </div>

            <div className="tab-content" id="pills-tabContent" style={{padding: "2em", marginBottom: "10em"}}>
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="row g-4">
                        {tournaments.map(tournament => (
                            <div key={tournament.service} className="col-md-6 col-lg-4">
                                <div className="price-box" style={{minHeight: "350px"}}>
                                    <h3>{tournament.name}</h3>
                                    <div style={{display: "flex", justifyContent: "center", marginTop: "4em"}}>
                                        <h2 style={{fontSize: "18px", marginRight: "0.5em", marginTop: "0px", marginBottom: "20px"}}>Date: </h2>
                                        <h2 style={{fontSize: "18px",marginTop: "0px", marginBottom: "20px"}}>{tournament.date}</h2>
                                    </div>
                                    {/* <div style={{display: "flex", justifyContent: "center"}}>
                                        <h2 style={{fontSize: "16px", margin: 0, width: "75%"}}>{tournament.details}</h2>
                                    </div> */}
                                    {tournament.disabled ?
                                    null :
                                    <div style={{display: "flex", justifyContent: "center", marginTop: "4em"}}>
                                        <div className="btn-block">
                                            <div className="membership-btn">
                                                <a href="/contact">Signup</a>
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Tournaments