import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { rateSheet } from "../../lib/tmpData"

const Membership = () => {
    const [formData,setFormData] = useState({first_name: "", last_name: "", email: "", phone: "", birthday: "", address: "", type: "", pcs: "N"})
    const navigate = useNavigate()
    const handleSideNav = () => {
        if (document.querySelector(".main-nav").classList.contains("slidenav")) {
            document.querySelector(".main-nav").classList.remove("slidenav")
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/email/new-member`,formData)
            console.log(response)
            navigate("/appt")
        } catch (error) {
            console.log(error)
            window.location.reload()   
        }
    }

    return (
        <div onClick={handleSideNav}>

            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb-inner">
                                    <h1>Membership</h1>
                                    <span><a href="/">Home<i className="fas fa-angle-right"></i></a>Membership</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{display: "flex", justifyContent: "center", marginTop: "2em", marginBottom: "2em"}}>
                <div className="col-md-7 col-xl-6" style={{display: "flex", justifyContent: "center"}}>
                    <div className="contact-form">
                        <h3>Join Riverside</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <input required type="text" name="first_name" placeholder="First Name" onChange={(e) => setFormData({...formData, first_name: e.target.value})} />
                                </div>
                                <div className="col-md-6">
                                    <input required type="text" name="last_name" placeholder="Last Name" onChange={(e) => setFormData({...formData, last_name: e.target.value})} />
                                </div>
                                <div className="col-md-6">
                                    <input required type="email" name="email" placeholder="Your Email" onChange={(e) => setFormData({...formData, email: e.target.value})} />
                                </div>
                                <div className="col-md-6">
                                    <input required type="text" name="phone" placeholder="Phone #" onChange={(e) => setFormData({...formData, phone: e.target.value})} />
                                </div>
                                <div className="col-md-6">
                                    <select required className="dropdown" onChange={(e) => setFormData({...formData, type: e.target.value})} defaultValue="">
                                        <option value="" disabled>Select Membership Type</option>
                                        <option value={"Single"}>Single</option>
                                        <option value={"Couple"}>Couple</option>
                                        <option value={"Family"}>Family</option>
                                        <option value={"Associate"}>Associate</option>
                                        <option value={"Junior"}>Junior</option>
                                        <option value={"Social"}>Social</option>
                                        <option value={"Corporate"}>Corporate</option>
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <div className="btn-group" role="group" aria-label="Cart Storage">
                                        <span id="pcs">Personal Cart Storage</span>
                                        <input type="radio" class="btn-check" name="btnradio" id="btnradioY" autocomplete="off" onChange={() => setFormData({...formData, pcs: "Y"})} />
                                        <label class="btn btn-outline-primary" htmlFor="btnradioY">Yes</label>
                                        <input type="radio" class="btn-check" name="btnradio" id="btnradioN" autocomplete="off" onChange={() => setFormData({...formData, pcs: "N"})} />
                                        <label class="btn btn-outline-primary" htmlFor="btnradioN">No</label>
                                    </div>
                                </div>
                            </div>
                            <input required type="date" name="birthday" placeholder="Birthday" onChange={(e) => setFormData({...formData, birthday: e.target.value})} />
                            <input required type="text" name="address" placeholder="Home Address" onChange={(e) => setFormData({...formData, address: e.target.value})} />
                            <div className="button--wrap">
                                <button type="submit" className="eg-btn btn--primary golf-btn mx-auto">Join<i className="bi bi-arrow-right"></i></button>
                            </div>
                        
                        </form>
                    </div>
                </div>
            </div>

            <div className="tab-content" id="pills-tabContent" style={{padding: "2em"}}>
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <div className="row g-4">
                        {rateSheet.map(rate => (
                            <div key={rate.service} className="col-md-6 col-lg-4">
                                <div className="price-box" style={{minHeight: "550px"}}>
                                    <h3>{rate.service}</h3>
                                    <h2>${rate.price}<sub>/Per Year</sub></h2>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <h2 style={{fontSize: "18px", marginRight: "0.5em", marginTop: "0px", marginBottom: "20px"}}>Quarterly Minimum:</h2>
                                        <h2 style={{fontSize: "18px",marginTop: "0px", marginBottom: "20px"}}>${rate.quarterlyMin}</h2>
                                    </div>
                                    {rate?.dependent ?
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h2 style={{fontSize: "18px", marginRight: "0.5em", marginTop: "0px", marginBottom: "20px"}}>Dependents:</h2>
                                            <h2 style={{fontSize: "18px",marginTop: "0px", marginBottom: "20px"}}>${rate.dependent}</h2>
                                        </div>
                                        :
                                        null    
                                    }
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <h2 style={{fontSize: "16px", margin: 0, width: "75%"}}>{rate.info}</h2>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Membership