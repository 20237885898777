import axios from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const Contact = () => {
    const [formData,setFormData] = useState({ name: "", email: "", phone: "", subject: "", message: "" })
    const navigate = useNavigate()
    const handleSideNav = () => {
        if (document.querySelector(".main-nav").classList.contains("slidenav")) {
            document.querySelector(".main-nav").classList.remove("slidenav")
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(formData)
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE}/email/contact`,formData)
            console.log(response)
            navigate("/cthk")
        } catch (error) {
            console.log(error)
            window.location.reload()   
        }
    }

    return (
        <div onClick={handleSideNav}>

            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb-inner">
                                    <h1>Contact Us</h1>
                                    <span><a href="/">Home<i className="fas fa-angle-right"></i></a>Contact Us</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="contact-area sec-mar">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-md-5 col-xl-5">
                            <div className="office-information">
                                <h2>Chat with us today!</h2>
                                <div className="single-information">
                                    <div className="bg-shape">
                                        <img src="assets/img/info-shape.png" alt=""/>
                                    </div>
                                    <div className="icon">
                                        <i><img src="assets/img/icons/location.png" alt=""/></i>
                                    </div>
                                    <div className="info-cnt">
                                        <h6>Location</h6>
                                        <p>3459 14th Ave, Menominee, MI 49858</p>
                                    </div>
                                </div>
                                <div className="single-information">
                                    <div className="bg-shape">
                                        <img src="assets/img/info-shape.png" alt=""/>
                                    </div>
                                    <div className="icon">
                                        <i><img src="assets/img/icons/mobile.png" alt=""/></i>
                                    </div>
                                    <div className="info-cnt">
                                        <h6>Phone</h6>
                                        <a href="tel:19068634837">+1-906-863-4837</a>
                                    </div>
                                </div>
                                <div className="single-information">
                                    <div className="bg-shape">
                                        <img src="assets/img/info-shape.png" alt=""/>
                                    </div>
                                    <div className="icon">
                                        <i><img src="assets/img/icons/envelope.png" alt=""/></i>
                                    </div>
                                    <div className="info-cnt">
                                        <h6>Email</h6>
                                        <a href="mailto:proshop@riversideccgolf.com">proshop@riversideccgolf.com</a>
                                        <a href="mailto:lexi@riversideccgolf.com">lexi@riversideccgolf.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 col-xl-7">
                            <div className="contact-form">
                                <h3>Write A Message</h3>
                                <form onSubmit={handleSubmit}>
                                    <input type="text" name="name" placeholder="Your Full Name" onChange={(e) => setFormData({...formData, name: e.target.value})} />
                                    <input type="email" name="email" placeholder="Your Email" onChange={(e) => setFormData({...formData, email: e.target.value})} />
                                    <input type="text" name="phone" placeholder="Your Phone" onChange={(e) => setFormData({...formData, phone: e.target.value})} />
                                    <input type="text" name="subject" placeholder="Subject" onChange={(e) => setFormData({...formData, subject: e.target.value})} />
                                    <textarea name="message" cols="30" rows="10" placeholder="Write Message" onChange={(e) => setFormData({...formData, message: e.target.value})} ></textarea>
                                    <div className="button--wrap">
                                        <button type="submit" className="eg-btn btn--primary golf-btn mx-auto">Submit Now <i className="bi bi-arrow-right"></i></button>
                                    </div>
                                
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="google-map">
                <div className="container-fluid g-0">
                    <div className="map">
                        <div className="row g-0">
                            <div className="col">
                                <iframe src="https://maps.google.com/maps?q=3459%2014th%20Ave,%20Menominee,%20MI%2049858&t=&z=10&ie=UTF8&iwloc=&output=embed"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Contact