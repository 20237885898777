const Construction = () => {
    const handleSideNav = () => {
        if (document.querySelector(".main-nav").classList.contains("slidenav")) {
            document.querySelector(".main-nav").classList.remove("slidenav")
        }
    }
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", minHeight: "100vh", justifyContent: "center"}} onClick={handleSideNav}>
            <div>
                <img src="assets/img/blog/img-cunstruct-2.svg" alt="construction" />
            </div>
            <div>
                <h2>Under Construction</h2>
            </div>
            <div>
                <p className="text-muted">Please check out this link later. We are doing some maintenance on it right now.</p>
            </div>
            <div>
                <a className="btn btn-primary" style={{borderRadius: "14px"}} href="/">Back to home</a>
            </div>
        </div>
    )
}

export default Construction