import { features } from "../../../lib/tmpData"
import Feature from "./Feature"

const Features = () => {
    return (
        <div className="feature-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-6">
                        <div className="title white text-center">
                            <span>feature</span>
                            <h2>Course Stats</h2>
                        </div>
                    </div>
                </div>
                <div className="feature">
                    <div className="row g-4 justify-content-center">
                        {features.map(feat => <Feature props={feat} key={feat.title} />)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features