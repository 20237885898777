const Blog = () => {
    const handleSideNav = () => {
        if (document.querySelector(".main-nav").classList.contains("slidenav")) {
            document.querySelector(".main-nav").classList.remove("slidenav")
        }
    }
    return (

        <div onClick={handleSideNav}>

            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb-inner">
                                    <h1>Club News</h1>
                                    <span><a href="/">Home<i className="fas fa-angle-right"></i></a>Club News</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="latest-blog grid sec-mar">
                <div class="container">
                    <div class="row g-4">
                        <div class="col-md-6 col-lg-4">
                            <div class="single-post">
                                <div class="post-thumbnail">
                                    <a href="blog-details.html"><img src="assets/img/blog/blog-1.jpg" alt=""/></a>
                                </div>
                                <div class="news-cnt">
                                    <span>07 January, 2022</span>
                                    <h3><a href="blog-details.html">Faucibus orci luctus etenda ultrices posuere cubilia.</a></h3>
                                    <div class="view-btn">
                                        <a href="blog-details.html">View Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="single-post">
                                <div class="post-thumbnail">
                                    <a href="blog-details.html"><img src="assets/img/blog/blog-2.jpg" alt=""/></a>
                                </div>
                                <div class="news-cnt">
                                    <span>08 January, 2022</span>
                                    <h3><a href="blog-details.html">Elementum id est Curabituri et tortor molestie.</a></h3>
                                    <div class="view-btn">
                                        <a href="blog-details.html">View Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="single-post">
                                <div class="post-thumbnail">
                                    <a href="blog-details.html"><img src="assets/img/blog/blog-3.jpg" alt=""/></a>
                                </div>
                                <div class="news-cnt">
                                    <span>09 January, 2022</span>
                                    <h3><a href="blog-details.html">Cras non auctor elit rutrum Suspendisse ultrices.</a></h3>
                                    <div class="view-btn">
                                        <a href="blog-details.html">View Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="single-post">
                                <div class="post-thumbnail">
                                    <a href="blog-details.html"><img src="assets/img/blog/blog-4.jpg" alt=""/></a>
                                </div>
                                <div class="news-cnt">
                                    <span>09 January, 2022</span>
                                    <h3><a href="blog-details.html">Quis massa iaculis mollis on ante et laoreet arcu.</a></h3>
                                    <div class="view-btn">
                                        <a href="blog-details.html">View Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="single-post">
                                <div class="post-thumbnail">
                                    <a href="blog-details.html"><img src="assets/img/blog/blog-5.jpg" alt=""/></a>
                                </div>
                                <div class="news-cnt">
                                    <span>09 January, 2022</span>
                                    <h3><a href="blog-details.html">Proin mattis viverra finibus Vivamus auctor elit.</a></h3>
                                    <div class="view-btn">
                                        <a href="blog-details.html">View Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="single-post">
                                <div class="post-thumbnail">
                                    <a href="blog-details.html"><img src="assets/img/blog/blog-6.jpg" alt=""/></a>
                                </div>
                                <div class="news-cnt">
                                    <span>09 January, 2022</span>
                                    <h3><a href="blog-details.html">Amet consequat aliquam se nisl lectus laoreet. </a></h3>
                                    <div class="view-btn">
                                        <a href="blog-details.html">View Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="single-post">
                                <div class="post-thumbnail">
                                    <a href="blog-details.html"><img src="assets/img/blog/blog-7.jpg" alt=""/></a>
                                </div>
                                <div class="news-cnt">
                                    <span>09 January, 2022</span>
                                    <h3><a href="blog-details.html">Nulla a imperdiet eros torto Suspendisse molestie.</a></h3>
                                    <div class="view-btn">
                                        <a href="blog-details.html">View Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="single-post">
                                <div class="post-thumbnail">
                                    <a href="blog-details.html"><img src="assets/img/blog/blog-8.jpg" alt=""/></a>
                                </div>
                                <div class="news-cnt">
                                    <span>09 January, 2022</span>
                                    <h3><a href="blog-details.html">In hac habitasse platea met dictumst Cras mi.</a></h3>
                                    <div class="view-btn">
                                        <a href="blog-details.html">View Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <div class="single-post">
                                <div class="post-thumbnail">
                                    <a href="blog-details.html"><img src="assets/img/blog/blog-9.jpg" alt=""/></a>
                                </div>
                                <div class="news-cnt">
                                    <span>09 January, 2022</span>
                                    <h3><a href="blog-details.html">Cras non auctor elit rutrum Suspendisse ultrices.</a></h3>
                                    <div class="view-btn">
                                        <a href="blog-details.html">View Details</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pagaination">
                        <ul class="paginate">
                            <li><a href="#"><i class="fas fa-angle-double-left"></i></a></li>
                            <li class="active"><a href="#">1</a></li>
                            {/* <li><a href="#">2</a></li> */}
                            {/* <li><a href="#">3</a></li> */}
                            <li><a href="#"><i class="fas fa-angle-double-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blog