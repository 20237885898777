import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Landing from './components/Landing/Landing'
import Construction from './components/Construction/Construction'
import Membership from './components/Membership/Membership'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import ClubHistory from './components/ClubHistory/ClubHistory'
import Blog from './components/Blog/Blog'
import ApplicationThanks from './components/ApplicationThanks/ApplicationThanks'
import Dining from './components/Dining/Dining'
import Tournaments from './components/Tournaments/Tournaments'
import Contact from './components/Contact/Contact'
import ContactThanks from './components/ContactThanks/ContactThanks'
import Events from './components/Events/Events'
import CourseLayout from './components/CourseLayout/CourseLayout'
import Lessons from './components/Lessons/Lessons'

const App = () => {
    return (
        <Router>
            <Header/>
            <Routes>
                <Route path='/' element={<Landing/>} />
                <Route path='/membership' element={<Membership/>} />
                <Route path='/history' element={<ClubHistory/>} />
                <Route path='/news' element={<Blog/>} />
                <Route path='/construction' element={<Construction/>} />
                <Route path='/appt' element={<ApplicationThanks/>} />
                <Route path='/cthk' element={<ContactThanks/>} />
                <Route path='/dining' element={<Dining/>} />
                <Route path='/tournaments' element={<Tournaments/>} />
                <Route path='/contact' element={<Contact/>} />
                <Route path='/events' element={<Events/>} />
                <Route path='/courselayout' element={<CourseLayout/>} />
                <Route path='/lessons' element={<Lessons/>} />
            </Routes>
            <Footer/>
        </Router>
    )
}

export default App