const Lessons = () => {
    const handleSideNav = () => {
        if (document.querySelector(".main-nav").classList.contains("slidenav")) {
            document.querySelector(".main-nav").classList.remove("slidenav")
        }
    }

    return (
        <div onClick={handleSideNav}>

            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb-inner">
                                    <h1>Golf Lessons</h1>
                                    <span><a href="/">Home<i className="fas fa-angle-right"></i></a>Golf Lessons</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field-review sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-xl-6 or2">
                            <div className="review-content">
                                <div className="title">
                                    <span>Evan Kramer</span>
                                    <h2>Meet Our Pro</h2>
                                </div>
                                <p>Evan is local to the tri cities area and had the privilege of working under PGA Professional Dave Spengler at Riverside while he was in high school. Evan pursued a bachelor's degree in business administration with a Professional Golf Management Accreditation from Ferris State University. During this time he also spent 3 seasons working at Sand Valley Golf Resort in Nekoosa, WI. This establishment is recognized as one of the top golf resorts in the country. He also spent 2 years serving as the Tournament Director at the elite-private Golf Club at Briar’s Creek. He is passionate about the game and loves helping others improve. He is a proud member in good standing with the Wisconsin PGA, with plans to bring a lot of excitement and changes to our golf operations that our members and guests will benefit from.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-xl-6 or1">
                            <div className="field-img">
                                <img src="assets/img/team/evan.jpg" alt="Evan" style={{maxWidth: "500px"}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pricing-plan sec-mar-bottom" style={{marginTop: "5em"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7">
                            <div className="title text-center">
                                <span>Pricing plan</span>
                                <h2>Get the Best Plan for you</h2>
                            </div>
                        </div>
                    </div>
                    <div className="pricing-boxes">
                        <div className="row g-4 justify-content-center">
                            <div className="col-md-6 col-lg-4">
                                <div className="price-box">
                                    <h3>Adult</h3>
                                    <h2>$45.00<sub>/Per Session</sub></h2>
                                    <ul className="item-list">
                                        <li><i className="bi bi-check"></i> 30 Minute Session</li>
                                        <li><i className="bi bi-check"></i> Lesson and Practice Plan</li>
                                        <li><i className="bi bi-check"></i> Premium Range Balls</li>
                                    </ul>
                                    <div className="price-btn">
                                        <a href="/contact">Book Session<i className="bi bi-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="price-box">
                                    <h3>Senior</h3>
                                    <h2>$35.00<sub>/Per Session</sub></h2>
                                    <ul className="item-list">
                                        <li><i className="bi bi-check"></i> 30 Minute Session</li>
                                        <li><i className="bi bi-check"></i> Lesson and Practice Plan</li>
                                        <li><i className="bi bi-check"></i> Premium Range Balls</li>
                                    </ul>
                                    <div className="price-btn">
                                        <a href="/contact">Book Session<i className="bi bi-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="price-box">
                                    <h3>Junior</h3>
                                    <h2>$25.00<sub>/Per Session</sub></h2>
                                    <ul className="item-list">
                                        <li><i className="bi bi-check"></i> 30 Minute Session</li>
                                        <li><i className="bi bi-check"></i> Lesson and Practice Plan</li>
                                        <li><i className="bi bi-check"></i> Premium Range Balls</li>
                                    </ul>
                                    <div className="price-btn">
                                        <a href="/contact">Book Session<i className="bi bi-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="price-box">
                                    <div className="percent">
                                        <span>save 20%</span>
                                    </div>
                                    <h3>Adult Bundle</h3>
                                    <h2>$180.00<sub> Bundle</sub></h2>
                                    <ul className="item-list">
                                        <li><i className="bi bi-check"></i> 5 Sessions</li>
                                        <li><i className="bi bi-check"></i> 30 Minute Sessions</li>
                                        <li><i className="bi bi-check"></i> Lesson and Practice Plan</li>
                                        <li><i className="bi bi-check"></i> Premium Range Balls</li>
                                    </ul>
                                    <div className="price-btn">
                                        <a href="/contact">Book Session<i className="bi bi-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="price-box">
                                    <div className="percent">
                                        <span>save 20%</span>
                                    </div>
                                    <h3>Senior Bundle</h3>
                                    <h2>$140.00<sub> Bundle</sub></h2>
                                    <ul className="item-list">
                                        <li><i className="bi bi-check"></i> 5 Sessions</li>
                                        <li><i className="bi bi-check"></i> 30 Minute Sessions</li>
                                        <li><i className="bi bi-check"></i> Lesson and Practice Plan</li>
                                        <li><i className="bi bi-check"></i> Premium Range Balls</li>
                                    </ul>
                                    <div className="price-btn">
                                        <a href="/contact">Book Session<i className="bi bi-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="price-box">
                                    <div className="percent">
                                        <span>save 20%</span>
                                    </div>
                                    <h3>Junior Bundle</h3>
                                    <h2>$100.00<sub> Bundle</sub></h2>
                                    <ul className="item-list">
                                        <li><i className="bi bi-check"></i> 5 Sessions</li>
                                        <li><i className="bi bi-check"></i> 30 Minute Sessions</li>
                                        <li><i className="bi bi-check"></i> Lesson and Practice Plan</li>
                                        <li><i className="bi bi-check"></i> Premium Range Balls</li>
                                    </ul>
                                    <div className="price-btn">
                                        <a href="/contact">Book Session<i className="bi bi-arrow-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Lessons