const CourseLayout = () => {
    const handleSideNav = () => {
        if (document.querySelector(".main-nav").classList.contains("slidenav")) {
            document.querySelector(".main-nav").classList.remove("slidenav")
        }
    }

    return (
        <div onClick={handleSideNav}>


            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb-inner">
                                    <h1>Course Layout</h1>
                                    <span><a href="/">Home<i className="fas fa-angle-right"></i></a>Course Layout</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field-review sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-xl-6 or2">
                            <div className="review-content">
                                <div className="title">
                                    <span>Hole #1</span>
                                </div>
                                <p>Short Description of hole?</p>
                                <ul className="field-points">
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>Par 4</b></li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle" style={{color: "#e60000"}}></i> <b>Red Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle" style={{color: "#0033cc"}}></i> <b>Blue Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>White Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>Handicap:</b> 1</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 col-xl-6 or1">
                            <div className="field-img">
                                <img src="assets/img/field-review.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field-review sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-xl-6 or1">
                            <div className="field-img">
                                <img src="assets/img/field-review.png" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xl-6 or2">
                            <div className="review-content">
                                <div className="title">
                                    <span>Hole #2</span>
                                </div>
                                <p>Short Description of hole?</p>
                                <ul className="field-points">
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>Par 4</b></li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle" style={{color: "#e60000"}}></i> <b>Red Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle" style={{color: "#0033cc"}}></i> <b>Blue Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>White Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>Handicap:</b> 1</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field-review sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-xl-6 or2">
                            <div className="review-content">
                                <div className="title">
                                    <span>Hole #3</span>
                                </div>
                                <p>Short Description of hole?</p>
                                <ul className="field-points">
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>Par 4</b></li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle" style={{color: "#e60000"}}></i> <b>Red Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle" style={{color: "#0033cc"}}></i> <b>Blue Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>White Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>Handicap:</b> 1</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 col-xl-6 or1">
                            <div className="field-img">
                                <img src="assets/img/field-review.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field-review sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-xl-6 or1">
                            <div className="field-img">
                                <img src="assets/img/field-review.png" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xl-6 or2">
                            <div className="review-content">
                                <div className="title">
                                    <span>Hole #4</span>
                                </div>
                                <p>Short Description of hole?</p>
                                <ul className="field-points">
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>Par 4</b></li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle" style={{color: "#e60000"}}></i> <b>Red Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle" style={{color: "#0033cc"}}></i> <b>Blue Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>White Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>Handicap:</b> 1</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field-review sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-xl-6 or2">
                            <div className="review-content">
                                <div className="title">
                                    <span>Hole #5</span>
                                </div>
                                <p>Short Description of hole?</p>
                                <ul className="field-points">
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>Par 4</b></li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle" style={{color: "#e60000"}}></i> <b>Red Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle" style={{color: "#0033cc"}}></i> <b>Blue Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>White Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>Handicap:</b> 1</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 col-xl-6 or1">
                            <div className="field-img">
                                <img src="assets/img/field-review.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="field-review sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-xl-6 or1">
                            <div className="field-img">
                                <img src="assets/img/field-review.png" alt=""/>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xl-6 or2">
                            <div className="review-content">
                                <div className="title">
                                    <span>Hole #6</span>
                                </div>
                                <p>Short Description of hole?</p>
                                <ul className="field-points">
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>Par 4</b></li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle" style={{color: "#e60000"}}></i> <b>Red Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle" style={{color: "#0033cc"}}></i> <b>Blue Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>White Tee:</b> 390 Yards</li>
                                    <li style={{width: "100%"}}><i className="far fa-check-circle"></i> <b>Handicap:</b> 1</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CourseLayout