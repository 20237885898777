const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="footer-top">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-8">
                            <div className="footer-logo">
                                <a href="/"><img src="assets/img/logo.png" alt="" style={{minWidth: "300px"}} /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-middle">
                    <div className="row gy-5">
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-widget">
                                <div className="social-media">
                                    <h4>Find Us Here:</h4>
                                    <ul className="social-icons">
                                        <li><a href="http://www.instagram.com/riverside.countryclub"><i className="fab fa-instagram"></i></a></li>
                                        <li><a href="https://www.facebook.com/riversidegolfclubmenominee"><i className="fab fa-facebook-f"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-widget">
                                <h4>Our Club</h4>
                                <ul className="footer-menu">
                                    <li><a href="/construction">Events</a></li>
                                    <li><a href="/tournaments">Tournaments</a></li>
                                    <li><a href="/construction">Course Layout</a></li>
                                    <li><a href="/dining">Dining</a></li>
                                    <li><a href="/membership">Membership</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="footer-widget">
                                <h4>Contacts</h4>
                                <div className="number">
                                    <div className="num-icon">
                                        <i><img src="assets/img/icons/phone-2.svg" alt=""/></i>
                                    </div>
                                    <div className="phone">
                                        <a href="tel:9068634837">906-863-4837</a>
                                        {/* <a href="tel:06571111576">+880 657 1111 576</a> */}
                                    </div>
                                </div>
                                <div className="office-mail">
                                    <div className="mail-icon">
                                        <i className="far fa-envelope"></i>
                                    </div>
                                    <div className="email">
                                        <a href="tell:proshop@riversideccgolf.com">proshop@riversideccgolf.com</a>
                                        <a href="tell:lexi@riversideccgolf.com">lexi@riversideccgolf.com</a>
                                    </div>
                                </div>
                                <div className="address">
                                    <div className="address-icon">
                                        <i className="fas fa-map-marker-alt"></i>
                                    </div>
                                    <p>3459 14th Ave, Menominee, MI 49858</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                            <div className="copy-txt">
                                <span>Copyright 2023 Riverside Country Club <b></b> | Powered By <a href="https://g-2technologies.com">G2</a><a href="https://g-2technologies.com"><img src="assets/img/g2logo.png" alt="g2" style={{width: "20px", marginLeft: "0.5em"}} /></a></span>
                            </div>
                        </div>
                        <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                            <ul className="footer-bottom-menu">
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Terms of Use</a></li>
                                <li><a href="#">Support Policy</a></li>
                                <li><a href="#">Terms of Service</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer