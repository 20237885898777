export const clubServices = [
    { img: "assets/img/golf.jpg", title: "Golf", subTitle: "" },
    { img: "assets/img/dine.jpg", title: "Dine", subTitle: "", link: "/dining" },
    { img: "assets/img/events.jpg", title: "Events", subTitle: "" },
    { img: "assets/img/golf-membership.jpg", title: "Join", subTitle: "", link: "/membership" },
    { img: "assets/img/calendar.jpg", title: "Tournaments", subTitle: "", link: "tournaments" },
    { img: "assets/img/facilities.jpg", title: "Facilities", subTitle: "" }
]

export const features = [
    { img: "assets/img/icons/feature-icon-1.png", num: 18, title: "Holes" },
    { img: "assets/img/icons/feature-icon-2.png", num: 72, title: "Par" },
    { img: "assets/img/icons/feature-icon-3.png", num: 6279, title: "Yards" },
    // { img: "assets/img/icons/feature-icon-4.png", num: 80, title: "Members" },
]

export const facilities = [
    { img: "assets/img/icons/facility-icon-1.svg", title: "Restaurant" },
    { img: "assets/img/icons/facility-icon-2.svg", title: "Swimming Pool" },
    { img: "assets/img/icons/facility-icon-3.svg", title: "Locker Room" },
    { img: "assets/img/icons/facility-icon-4.svg", title: "Fitness Jone" },
    { img: "assets/img/icons/facility-icon-5.svg", title: "Party Center" }
]  

export const rateSheet = [
    { service: "Single", price: "2099", subTitle: "Single Membership", quarterlyMin: "150", info: "Membership includes full access to the course and club for a single individual" },
    { service: "Couple", price: "2999", subTitle: "Couple Membership", quarterlyMin: "200", info: "Membership includes full access to the course and club for a couple living under the same roof" },
    { service: "Family", price: "2999", subTitle: "Single Membership", quarterlyMin: "200", dependent: "499", info: "Membership includes full access to the course and club for a family unit with the ability to add dependents living under the same roof." },
    { service: "Associate", price: "1499", subTitle: "Associate Membership", quarterlyMin: "100", info: "Membership includes full access to the course and club for a single individual between the ages of 19 and 29. " },
    { service: "Junior", price: "749", subTitle: "Junior Membership", quarterlyMin: "0", info: "Membership includes full access to the course and club for a single individual under the age of 19." },
    { service: "Social", price: "749", subTitle: "Social Membership", quarterlyMin: "100", info: "Membership includes access to the club and club events, as well as discounted greens fees for up to 8 rounds per season." },
    { service: "Corporate", price: "7499", subTitle: "Corporate Membership", quarterlyMin: "0", info: "Membership includes one tee time per day for up to 4 designated individuals, discounted room rentals, and advertising on one of our signature holes. Only 18 corporate memberships available." },
]

export const tournaments = [
    { name: "2-Person Big Cup Scramble", date: "9-21-2024", disabled: false, document: "/" },
    { name: "Club Championship", date: "8-3-2024 | 8-4-2024", disabled: true, document: "/" },
    { name: "2-Man Best Ball", date: "7-13-2024 | 7-14-2024", disabled: true, document: "/" },
    { name: "3-Man Scramble", date: "6-8-2024 | 6-9-2024", disabled: true, document: "/" },
]