import MemberForm from "./MemberForm"

const FacilityMembership = () => {
    return (
        <div className="facility-membership">
            <div className="container-fluid g-0">
                <div className="facilities-wrapper">
                    <div className="facility-left">
                        <div className="facility-img">
                            <img src="assets/img/membership.jpg" alt=""/>
                        </div>
                    </div>
                    <div className="facility-right">
                        <div className="membership-form">
                            <div className="title white">
                                <span>Membership</span>
                                <h3 style={{fontFamily: "Lato, sans-serif", color: "white"}}>Join the Riverside Community!</h3>
                            </div>
                            <MemberForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FacilityMembership