const LatestBlog = () => {
    return (
        <div className="latest-blog sec-mar-top sec-pad-bottom">
            <div className="container">
                <div className="row">
                    <div className="title text-center">
                        <span>Club News</span>
                        <h2>Latest Newsletters</h2>
                    </div>
                </div>
                <div className="row g-4 justify-content-center">
                    <div className="col-md-6 col-lg-4">
                        <div className="single-post">
                            <div className="post-thumbnail">
                                <a href="/construction"><img src="assets/img/blog/blog1.jpg" alt=""/></a>
                            </div>
                            <div className="news-cnt">
                                <span>01 January, 2024</span>
                                <h3><a href="/construction">January Newsletter</a></h3>
                                <div className="view-btn">
                                    <a href="/construction">View Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                        <div className="single-post">
                            <div className="post-thumbnail">
                                <a href="/construction"><img src="assets/img/blog/blog2.jpg" alt=""/></a>
                            </div>
                            <div className="news-cnt">
                                <span>01 February, 2024</span>
                                <h3><a href="/construction">February Newsletter</a></h3>
                                <div className="view-btn">
                                    <a href="/construction">View Details</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LatestBlog