import FacilityMembership from "./FacilityMembership/FacilityMembership"
import Features from "./Features/Features"
import Hero from "./Hero"
import LatestBlog from "./LatestBlog"
import Club from "../Club/Club"

const Landing = () => {

    const handleSideNav = () => {
        if (document.querySelector(".main-nav").classList.contains("slidenav")) {
            document.querySelector(".main-nav").classList.remove("slidenav")
        }
    }
    
    return (
        <div onClick={handleSideNav} style={{overflowX: "hidden"}}>
            <Hero />
            <div className="row justify-content-center p-2" style={{marginTop: "4em", marginBottom: "4em"}}>
                <div className="col-sm-10 col-md-8 col-lg-6">
                    <h3 style={{textAlign: "center"}}>The UP's Oldest Golf Course</h3>
                    <p style={{textAlign: "center"}}>The doorway to great golf in Michigan’s beautiful Upper Peninsula! We are excited to invite you to the UP’s oldest golf course, founded in 1901. The 18-hole layout features unique and challenging holes that create a great golf experience for golfers of all levels, with greens that will rival some of the best courses in the state! Fine dining, banquet spaces, lounging patio, fitness center, saunas, and more round out an extensive offering for a true country club atmosphere. Riverside Country Club invites members and the public alike to come and enjoy all that we have to offer!</p>
                </div>
            </div>
            {/* <About /> */}
            <Club />
            <Features />
            {/* <FieldReview /> */}
            {/* <Pricing /> */}
            <FacilityMembership />
            {/* <Testimonial /> */}
            <LatestBlog />
            <div className="google-map">
                <div className="container-fluid g-0">
                    <div className="map">
                        <div className="row g-0">
                            <div className="col">
                                <iframe
                                    src="https://maps.google.com/maps?q=3459%2014th%20Ave,%20Menominee,%20MI%2049858&t=&z=10&ie=UTF8&iwloc=&output=embed"
                                    title="map"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing