const Hero = () => {
    return (
        <div className="hero-area">
            <div className="swiper hero-slider">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="hero-single bg-1">
                            <div className="" style={{minHeight: "100vh", display: "flex", flexDirection: "column", justifyContent: "end"}}>
                                <div style={{display: "flex", justifyContent: "end", color: "white"}}>
                                    <h1 style={{padding: "1em", marginRight: "1em", fontFamily: "Lato, sans-serif", borderTop: "1px solid white", fontWeight: 500 }}>Riverside Country Club</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero