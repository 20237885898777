const ClubHistory = () => {

    const handleSideNav = () => {
        if (document.querySelector(".main-nav").classList.contains("slidenav")) {
            document.querySelector(".main-nav").classList.remove("slidenav")
        }
    }
    return (

        <div onClick={handleSideNav}>

            <div className="breadcrumb">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb-inner">
                                    <h1>Club History</h1>
                                    <span><a href="/">Home<i className="fas fa-angle-right"></i></a>Club History</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-xl-6">
                            <div className="about-left">
                                <div className="title">
                                    <span>About Us</span>
                                    <h2>About Our Golf Club.</h2>
                                </div>
                                {/* <div className="special-message">
                                    <div className="lqt">
                                        <i className="fas fa-quote-left"></i>
                                    </div>
                                    <p>Need to be sure there isn't anything embarrassing thate hidden in the middle of text. All the generators on then Internet tend to repeat predefined chunks as necessary.</p>
                                    <div className="rqt">
                                        <i className="fas fa-quote-right"></i>
                                    </div>
                                    <h6>Albino Lang</h6>
                                </div> */}
                                <p style={{marginTop: "1em"}}>Riverside Golf Club is located on the banks of the Menominee River. Riverside is the most popular golf course name in the United States according to ClubHouse Magazine. There are 46 Riversides' in the US and the oldest member of that group is Riverside Golf Club in Menominee, Michigan.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-xl-6">
                            <div className="about-right">
                                <div className="right-top">
                                    <img src="assets/img/club/riverside-1901.jpg" alt=""/>
                                    <div className="shape">
                                        <img src="assets/img/shape.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="club-history sec-mar-bottom">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="title text-center">
                                <span>Club History</span>
                                <h2>History of Riverside Golf</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="history-timeline">
                                <div className="single-history">
                                    <span>1901</span>
                                    <div className="hisoty-inner">
                                        <div className="circl-wave"></div>
                                        <h4>Origins of Riverside Country Club</h4>
                                        <p>Finding it impossible to maintain separate clubs in Menominee, Michigan and its siter city, Marinette, Wisconsin, the leading golfers of both clubs held a meeting on May 24, 1901 and formed one club, Riverside Country Club.</p>
                                    </div>
                                </div>
                                <div className="single-history">
                                    <span>1901</span>
                                    <div className="hisoty-inner">
                                        <div className="circl-wave"></div>
                                        <h4>Grand Opening</h4>
                                        <p>The official grand opening of the clubhouse and course took place on June 27, 1901 with 84 active members attending the gala event. A family membership was $15.00 which was under the newspaper heading "Golf Expensive".</p>
                                    </div>
                                </div>
                                <div className="single-history">
                                    <span>1907</span>
                                    <div className="hisoty-inner">
                                        <div className="circl-wave"></div>
                                        <h4>Rebuilding After 1907 Fire</h4>
                                        <p>The first clubhouse was destroyed by a fire in 1907. It was replaced the following year when the Club purchased the old polo clubhouse and moved it to the golf course.</p>
                                    </div>
                                </div>
                                <div className="single-history">
                                    <span>1919</span>
                                    <div className="hisoty-inner">
                                        <div className="circl-wave"></div>
                                        <h4>Reorganization and Incorporation</h4>
                                        <p>In June of 1919, the Club was reorganized and incorporated under its present name and land was purchased outright.</p>
                                    </div>
                                </div>
                                <div className="single-history">
                                    <span>1921</span>
                                    <div className="hisoty-inner">
                                        <div className="circl-wave"></div>
                                        <h4>Expansion & Improvements</h4>
                                        <p>A small bond issue in 1921 permitted rehabilitation of the clubhouse and the purchase of more land to lengthen and standardize the course.</p>
                                    </div>
                                </div>
                                <div className="single-history">
                                    <span>1946</span>
                                    <div className="hisoty-inner">
                                        <div className="circl-wave"></div>
                                        <h4>Clubhouse Fire</h4>
                                        <p>Fire once again took its toll July 19, 1946 when Riverside's second clubhouse was destroyed.An oil burner used to heat water was considered the cause of the blaze.</p>
                                    </div>
                                </div>
                                <div className="single-history">
                                    <span>1947</span>
                                    <div className="hisoty-inner">
                                        <div className="circl-wave"></div>
                                        <h4>Resilient Rebuilding</h4>
                                        <p>Undaunted by these events, a group of members pooled their resources to begin again and the first portion of the present clubhouse was erected in 1947.</p>
                                    </div>
                                </div>
                                <div className="single-history">
                                    <span>1952</span>
                                    <div className="hisoty-inner">
                                        <div className="circl-wave"></div>
                                        <h4>Hood Memorial Addition</h4>
                                        <p>The Francis James Hood Memorial addition which housed the locker rooms and Pro Shop was built and dedicated in 1952.</p>
                                    </div>
                                </div>
                                <div className="single-history">
                                    <span>1966</span>
                                    <div className="hisoty-inner">
                                        <div className="circl-wave"></div>
                                        <h4>Clubhouse Renovation</h4>
                                        <p>In 1966-67 the club house was renovated and a beautiful new bar, lounge area, and second dining room were added. With these changes, the clubhouse was enlarged and became a facility for year round social events.</p>
                                    </div>
                                </div>
                                <div className="single-history">
                                    <span>1974</span>
                                    <div className="hisoty-inner">
                                        <div className="circl-wave"></div>
                                        <h4>Tennis Courts and Entrance</h4>
                                        <p>In 1974, two tennis courts and a new entrance to the grounds were added.</p>
                                    </div>
                                </div>
                                <div className="single-history">
                                    <span>1984</span>
                                    <div className="hisoty-inner">
                                        <div className="circl-wave"></div>
                                        <h4>Course Expansion</h4>
                                        <p>In 1985 the golf course was expanded to 18 holes. The new 9 holes were designed by Roger Packard, golf course architect and son of the late golf course designer, E. Lawrence Packard.</p>
                                    </div>
                                </div>

                                <div className="single-history">
                                    <span>1997</span>
                                    <div className="hisoty-inner">
                                        <div className="circl-wave"></div>
                                        <h4>More Renovations</h4>
                                        <p>In 1997 the clubhouse renovated the Hood Memorial additioni to include new Pro Shop, locker rooms, and a mixed grill for more casual dining.</p>
                                    </div>
                                </div>
                                <div className="single-history">
                                    <span>2009</span>
                                    <div className="hisoty-inner">
                                        <div className="circl-wave"></div>
                                        <h4>Transition to Riverside Golf Club</h4>
                                        <p>Riverside was sold in 2009 and became open to the public. The name was changed but everyone still referred to it as Riverside. Shortly after, the club was renamed Riverside Golf Club.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClubHistory