const Facility = ({props}) => {
    return (
        <div className="col-md-6 col-lg-4">
            <div className="single-facility">
                <div className="facilty-img">
                    <a href={props.link ? props.link : "/construction"}><img src={props.img} alt="" /></a>
                </div>
                <div className="facility-inner">
                    <span>{props.title}</span>
                    <div className="circle">
                        <h4>{props.subTitle}</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Facility